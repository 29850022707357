.main {
  background-color: #fff;
  min-height: 100vh;
  padding-right: 25px;
  padding-left: 10px;
}

.main-content {
  padding-top: 30px;
  margin-left: 10px;
}

.section {
  margin-top: 5px;
  padding: 0 10px 0 10px;
  line-height: 1.3;
  font-size: 0.8rem;
}
.main h1, 
.main h2, 
.main h3, 
.main h4, 
.main h5, 
.main h6 {
  color: #223545;
}

section p {
  margin: 5px 0 0 0;
}

#cursos p{
  margin: 3px 0 3px 0;
  font-size: 0.7rem;
}

#cursos a {
  color: #f00;
}

#cursos a:visited {
  color: #a00;
}

.date {
  color: #555;
  font-size: .7rem;
  margin: 0;
}

.xp {
  margin-top: 5px;
}

.text {
  font-size: 0.75rem;
}

@media only screen and (min-width: 601px) and (max-width: 700px) {
  .main {
  }
}

@media only screen and (max-width: 600px) {
  .main {
    max-width: 100% !important;
  }
}
