@media only screen and (max-width: 345px) {
  .contato-content {
    display: block;
    justify-content: center !important;
    flex-wrap: wrap !important;
    flex-direction: row !important;
  }
  .left, .right {
    display: flex;
    align-items: center !important;
    min-width: 51%;
  }
}
@media only screen and (max-width: 400px) {
  .chart-container {
    justify-content: center !important;
  }
}
@media only screen and (max-width: 600px) {
  .sidebar {
    width: 100% !important;
    max-width: 100% !important;
    min-width: initial;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 30px;
    min-height: auto !important;
    display: flex;
    justify-content: center;
  }
  .sidebar-content {
    width: 100%;
    justify-content: center;
    text-align: center;
  }
  .contato-content {
    display: flex;
    justify-content: space-around;
  }
  .contato p {
    display: initial !important;
  }

  .contato div p {
    display: flex !important;
  }
  
  .contato .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .contato .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .chart-container {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

@media only screen and (min-width: 601px) and (max-width: 700px) {
  .sidebar {
    width: 20% !important;
    min-width: 180px !important;
  }
}

.sidebar {
  background-color: #223545;
  color: #eee;
  width: 30%;
  min-width: 210px;
  min-height: 100vh;
  padding-left: 25px;
  padding-right: 10px;
}

.sidebar-content {
  padding-top: 30px;
}

.contato p{
  display: flex;
  align-items: center;
  margin: 5px 0 5px 0;
}

.contato-content {
  margin-top: 12px;
}

.contato p svg {
  margin-right: .2rem;
}
.contato a {
  text-decoration: none;
  color: #5cf;
}

.platform {
  margin-top: 3px;
}
.platform h6 {
  font-size: 0.8rem;
}
.platform p {
  line-height: 1.2;
  font-size: 0.75rem;
}

.chart-container {
  margin-top: 30px;
}
