@media only screen and (max-width: 400px) {
  .pie-container {
    max-width: 120px;
  }
  .pie-chart-container {
    max-width: 40px !important;
    margin: 5px;
  }
  .pie-label {
    font-size: 12px !important;
    margin-left: 6px !important;
  }
}
@media only screen and (min-width: 401px) and (max-width: 570px) {
  .pie-container {
    max-width: 160px;
  }
}

@media only screen and (min-width: 571px) and (max-width: 600px) {
  .pie-container {
    max-width: 180px;
  }
}

.pie-container {
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 80%;
}

.pie-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.pie-chart-container {
  max-width: 50px;
  margin: 5px;
}

.pie-label {
  font-size: 14px;
  margin-left: 10px;
}
