@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  font-family: 'Lato', sans-serif;
}

body {
  margin: 0;
  background-color: #eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
.h1 {
  font-size: 2.25rem;
}

h2,
.h2 {
  font-size: 1.8rem;
}

h3,
.h3 {
  font-size: 1.575rem;
}

h4,
.h4 {
  font-size: 1.35rem;
}

h5,
.h5 {
  font-size: 1.125rem;
}

h6,
.h6 {
  font-size: 0.9rem;
}

.container {
  display: flex;
  justify-content: center;
}

.content{
  max-width: 800px;
  display: flex;
  
}
h1, h2, h3, h4, h5, h6 {
  margin-top: 1rem;
  margin-bottom: 0;
}
.mb-0 {
  margin-bottom: 0;
}

@media print {
  h1,
.h1 {
  font-size: 1.8rem;
}

h2,
.h2 {
  font-size: 1.44rem;
}

h3,
.h3 {
  font-size: 1.26rem;
}

h4,
.h4 {
  font-size: 1.08rem;
}

h5,
.h5 {
  font-size: 0.9rem;
}

h6,
.h6 {
  font-size: 0.72rem;
}
  .sidebar {
    min-width: 180px;
    -webkit-print-color-adjust: exact;
  }
  body {
    margin: 0;
    padding: 0;
  }
  @page {
    size: auto; /* auto is the initial value */
    margin: 0;
    padding: 0;
  }

  .sidebar {
    padding-left: 35px !important;
    padding-right: 10px !important;
  }
  .main {
    padding-left: 10px !important;
    padding-right: 35px !important;
  }

  .pie-chart-container {
    max-width: 40px !important;
    margin: 5px;
  }
  .pie-label {
    font-size: 12px !important;
    margin-left: 6px !important;
  }
}

@media only screen and (max-width: 600px) {
  .content {
    flex-direction: column;
  }  
}
